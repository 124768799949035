<template>
  <div class="bg-img min-height flex-column justify-content-start align-items-center">
    <div class="block1 bg-white"></div>
    <div class="block2 bg-white"></div>
    <van-form class="width-100" @submit="onSubmit">
      <div class="block3 box-shadow bg-white width-100 br-reg">
        <van-cell-group>
          <div class="width-100 margin-bs flex-row justify-content-spaceBetween align-items-center">
            <div class="width-40 title">
              <p class="fw-mid">举报人信息</p>
            </div>
          </div>
          <van-field
            v-show="reportShow"
            v-model="form.reportName"
            name="reportName"
            label="举报人"
            placeholder="请输入举报人姓名"
            input-align="right"
            :rules="[{ validator: validatorAnonymous }]"
          />
          <van-field
            v-show="reportShow"
            v-model="form.reportMobile"
            name="reportMobile"
            label="举报人电话"
            placeholder="请输入举报人电话"
            input-align="right"
            :rules="[{ validator: validatorMobileAnonymous, message: '请输入正确的手机号码' }]"
          />
          <van-field input-align="right" name="isAnonymous" label="是否匿名">
            <template #input>
              <van-switch v-model="form.isAnonymous" size="20" @input="onAnonymousChange" />
            </template>
          </van-field>
          <div class="width-100 margin-bs margin-t flex-row justify-content-spaceBetween align-items-center">
            <div class="width-40 title">
              <p class="fw-mid">举报内容</p>
            </div>
          </div>
          <van-field v-model="form.childName" name="childName" label="未成年人姓名" placeholder="请输入未成年人姓名" input-align="right" />
          <van-field name="childGender" input-align="right" label="未成年人性别">
            <template #input>
              <van-radio-group v-model="form.childGender" direction="horizontal">
                <van-radio name="1">男</van-radio>
                <van-radio name="2">女</van-radio>
              </van-radio-group>
            </template>
          </van-field>

          <van-field v-model="form.childAge" name="childAge" label="未成年人年龄" placeholder="请输入未成年人年龄" input-align="right" />

          <van-field v-model="form.perpetratorName" name="perpetratorName" label="侵害人姓名" placeholder="请输入侵害人姓名" input-align="right" />

          <van-field input-align="right" name="perpetratorGender" label="侵害人性别">
            <template #input>
              <van-radio-group v-model="form.perpetratorGender" direction="horizontal">
                <van-radio name="1">男</van-radio>
                <van-radio name="2">女</van-radio>
              </van-radio-group>
            </template>
          </van-field>

          <van-field
            v-model="form.reportLocation"
            name="reportLocation"
            label="事件地点"
            placeholder="请输入事件地点"
            input-align="right"
            :rules="[{ required: true }]"
          />
          <van-field
            input-align="right"
            v-model="form.happenedTime"
            is-link
            readonly
            name="happenedTime"
            label="发现时间"
            placeholder="请选择事发时间"
            @click="showPicker = true"
            :rules="[{ required: true }]"
          />
          <van-popup v-model="showPicker" position="bottom">
            <van-datetime-picker
              v-model="currentDate"
              type="date"
              title="选择完整时间"
              :min-date="minDate"
              :max-date="maxDate"
              @confirm="onConfirm"
              @cancel="showPicker = false"
            />
          </van-popup>
          <p class="fs-mid darkgrey">事件概况</p>
          <van-field
            v-model="form.reportContent"
            name="reportContent"
            rows="4"
            autosize
            label=""
            type="textarea"
            placeholder="请详细描述事件经过"
            :rules="[{ required: true }]"
          />
          <p class="fs-mid darkgrey">附件 <span class="grey">(图片，最多上传9张)</span></p>
          <van-field>
            <template #input>
              <van-uploader v-model="imgList" max-count="9" :after-read="afterRead" />
            </template>
          </van-field>
        </van-cell-group>
      </div>
      <div class="width-60 textAlign-c" style="margin: 16px 20%">
        <van-button color="rgb(253,202,61)" class="" round block type="primary" native-type="submit"> 提交 </van-button>
      </div>
    </van-form>
  </div>
</template>
<script>
import { clueReportSave, uploadFile } from '@/api/reportApi';
import { Toast } from 'vant';
export default {
  name: 'clueReportForm',
  data() {
    return {
      form: {
        zlbId: '',
        reportName: '',
        reportMobile: '',
        isAnonymous: false,
        childName: '',
        childGender: '',
        childAge: '',
        perpetratorName: '',
        perpetratorGender: '',
        reportLocation: '',
        happenedTime: '',
        reportContent: '',
        reportImg: '',
        origin: 'wx'
      },
      reportShow: true,
      imgList: [],
      showPicker: false,
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date()
    };
  },
  created() {
    let zlbId = this.$Cookies.get('zlbId');

    if (zlbId == null) {
      this.$router.push('/clueReportIndex');
    }
    this.form.zlbId = zlbId;
    this.form.reportName = this.$Cookies.get('userName');
    this.form.reportMobile = this.$Cookies.get('userMobile');
  },
  methods: {
    jumpTo(e) {
      this.$router.push(e);
    },
    onAnonymousChange(checked) {
      if (checked) {
        this.reportShow = false;
      } else {
        this.reportShow = true;
      }
    },
    validatorMobile(val) {
      let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (val == '' || val <= 10 || !reg.test(val)) {
        return false;
      }
      return true;
    },
    validatorAnonymous(val) {
      if (this.form.isAnonymous) {
        return true;
      } else if (!val) {
        return false;
      }
      return true;
    },
    validatorMobileAnonymous(val) {
      let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (this.form.isAnonymous) {
        return true;
      } else if (val == '' || val <= 10 || !reg.test(val)) {
        return false;
      }
      return true;
    },
    onSubmit() {
      let imgs = '';
      for (let i = 0; i < this.imgList.length; i++) {
        if (imgs == '') {
          imgs += this.imgList[i].fileName;
        } else {
          imgs += ',' + this.imgList[i].fileName;
        }
      }
      if (this.form.isAnonymous) {
        this.form.reportName = '';
        this.form.reportMobile = '';
      }
      this.form.reportImg = imgs;
      let toast = Toast.loading({
        message: '提交中...',
        forbidClick: true,
        loadingType: 'spinner'
      });
      clueReportSave(this.form).then((response) => {
        toast.clear();
        if (response.code == 200) {
          Toast.success('提交成功');
          this.reset();
          setTimeout(function () {
            this.$router.push('/clueReportList');
          }, 1800);
        } else {
          Toast.fail('提交失败，请稍后再试');
        }
      });
    },
    onConfirm(value) {
      this.form.happenedTime = value.getFullYear() + '-' + (value.getMonth() + 1) + '-' + value.getDate();
      this.showPicker = false;
    },
    reset() {
      this.form = {
        zlbId: '',
        reportName: '',
        reportMobile: '',
        isAnonymous: false,
        childName: '',
        childGender: '',
        childAge: '',
        perpetratorName: '',
        perpetratorGender: '',
        reportLocation: '',
        happenedTime: '',
        reportContent: '',
        reportImg: '',
        origin: 'wx'
      };
      this.imgList = [];
    },
    afterRead(file) {
      file.status = 'uploading';
      file.message = '上传中...';

      let formFile = new FormData();
      formFile.append('file', file.file);

      uploadFile(formFile).then((response) => {
        if (response.code == 200) {
          file.status = 'done';
          file.message = '';
          file.url = response.url;
          file.fileName = response.fileName;
        } else {
          file.status = 'failed';
          file.message = '上传失败';
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.bg-img {
  background: url('../../assets/image/bg.jpg') center top no-repeat;
  background-size: 100%;
  background-color: #1243d4;
  width: 100%;
  padding: 14rem 5% 2rem;
}
.block1 {
  width: 80%;
  height: 0.8rem;
  border-radius: 2rem 2rem 0 0;
  opacity: 0.25;
}
.block2 {
  width: 90%;
  height: 0.8rem;
  border-radius: 2rem 2rem 0 0;
  opacity: 0.4;
}
.block3 {
  padding: 2rem 5%;
  margin-bottom: 3rem;
  .title {
    line-height: 2rem;
    background: url('../../assets/image/5.png') left bottom no-repeat;
    background-size: contain;
    p {
      color: rgb(29, 49, 113);
      font-size: 1.2rem;
    }
  }
  .list-box {
    width: 100%;
    padding: 1rem;
    background: rgb(241, 240, 254);
    .label {
      right: 0;
      top: 0.5rem;
      padding: 0.1rem 0.5rem 0.1rem 1rem;
    }
    .label1 {
      background: url('../../assets/image/pic3.png') center no-repeat;
      background-size: 100%;
    }
    .label2 {
      background: url('../../assets/image/pic4.png') center no-repeat;
      background-size: 100%;
    }
    .label3 {
      background: url('../../assets/image/pic5.png') center no-repeat;
      background-size: 100%;
    }
  }
}
/deep/.van-hairline--top-bottom::after {
  border: none;
}
/deep/.van-cell {
  //background: #fafafa;
  //padding: 6px 16px;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 10px;
  padding: 10px 0;
}
/deep/.van-cell::after {
  border: none;
}
/deep/.van-switch__node {
  width: 1.5em;
  height: 1.5em;
}
/deep/.van-switch {
  width: 1.7em;
}
</style>
